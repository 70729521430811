import React from 'react'

const MusicStaff = ({ ...props }) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      viewBox='0 0 400 400'
      {...props}
    >
      <path
        d='M281.69,181.68c-4.25-13.12-12.86-25.1-25.07-31.85c-6.29-3.3-13.37-5.44-20.48-5.7c-7.09,0.27-14.17,0.95-21.12,2.31
	c-2.13-11.8-4.46-23.62-6.04-35.52c0.39-0.45,0.78-0.9,1.17-1.35c6.74-7.85,13.09-16.02,19.23-24.34c3.59-5.34,6.68-11,9.81-16.61
	c1.94-4.01,4.03-7.94,5.84-12.01c1.63-4,2.44-8.26,3.43-12.44c1.52-5.89,2.34-12.03,1.01-18.04c-0.44-2.27-0.68-4.61-1.46-6.8
	c-1.08-2.16-2.53-4.16-4.28-5.82c-13.35-12.28-31.69-7.53-41.81,6.37c-3.91,4.42-6.64,9.71-8.53,15.27
	c-2.13,5.76-3.82,11.67-4.69,17.76c-1.4,9.15-1.73,18.39-1.58,27.63c-0.16,9.18,0.29,18.35,1.53,27.44
	c-5.86,6.67-11.94,13.14-18.4,19.23c-5.79,5.36-11.83,10.46-17.64,15.79c-6.81,6.65-13.07,13.95-18.01,22.11
	c-3.03,4.76-5,10.06-6.97,15.32c-2.89,8.19-5.15,16.66-6.27,25.29c-0.59,8.67,0.08,17.42,0.93,26.05c1.08,10.01,4.46,20,10.34,28.22
	c3.2,4.21,7.57,7.32,11.39,10.95c4.86,4.25,9.78,8.53,15.28,11.94c3.8,2.13,7.82,3.86,11.93,5.3c4.53,1.55,9.21,2.68,14,2.88
	c3.71,0.26,7.42-0.35,11.13-0.51c1.9-0.08,3.79-0.22,5.68-0.39c-0.19,1.21-0.4,2.42-0.65,3.62c-0.22,0.98-0.56,2.48-0.74,3.16
	c-2.69,9.99-5.45,20.01-8.98,29.74c-7.71,18.49-13.12,28.72-26.41,41.97c-0.98,0.89-1.79,1.63-2.49,2.28
	c-0.04-0.18-0.08-0.35-0.12-0.53c-0.66-5.03-3.53-9.25-6.77-13.01c-10.27-11.88-33.5-9.37-40.84,4.61
	c-2.86,5.53-4.09,13.01-0.14,18.29c4.23,5.49,10.58,8.98,17.17,10.86c3.91,1.21,7.88,2.18,11.98,2.31
	c11.77,0.86,23.01-4.4,31.6-12.17c22.18-17.93,34.7-44.71,41.77-71.8c2.44-7.47,3.97-15.05,5.03-22.73
	c1.45-0.35,2.89-0.72,4.32-1.11c7.89-2.1,15.41-5.65,22.25-10.08c5.73-4.14,10.94-9,16.01-13.91c5.82-6.31,11.16-13.3,14.94-21.03
	c3.36-8.05,6.57-16.45,7.42-25.19C288.23,203.94,285.79,192.4,281.69,181.68z M132.82,374.62c-0.13-0.29-0.24-0.58-0.34-0.89
	c-0.01-0.04-0.02-0.08-0.02-0.12c-0.12-1.29-0.04-2.58,0.16-3.86c0.23-0.84,0.65-2.26,1.02-2.92c0.26-0.59,0.57-1.16,0.9-1.72
	c0.32-0.43,0.66-0.84,1.03-1.23c0.1-0.1,0.19-0.19,0.27-0.26c0.38-0.27,0.76-0.52,1.16-0.76c0.02-0.01,0.03-0.02,0.05-0.03
	c0.15-0.06,0.3-0.12,0.46-0.17c0,0,0.01,0,0.01,0c0.6,0.6,1.17,1.23,1.7,1.89c0.61,0.77,1.23,1.54,1.81,2.33
	c0.85,1.45,1.47,3.02,1.86,4.65c0.11,0.6,0.22,1.2,0.28,1.8c-0.86,0.52-1.67,1.08-2.11,2.05c-1.12,1.39-1.09,2.9-0.43,4.25
	c-1.3-0.42-2.56-0.93-3.8-1.49c-0.65-0.39-1.29-0.85-1.64-1.1C134.34,376.29,133.53,375.49,132.82,374.62z M209.29,49.67
	c1.23-5.4,3.14-10.64,5.23-15.76c2.74-5.78,1.44-3.41,5.73-8.84c3.98-4.37,2.98-3.77,5.77-5.39c0.2,0.13,0.38,0.27,0.57,0.41
	c0.67,0.64,1.3,1.33,1.87,2.06c0.26,0.49,0.46,1,0.61,1.53c0.02,0.13,0.05,0.26,0.07,0.39c0.94,4.71,1.5,9.55,0.57,14.31
	c-0.08,0.48-0.19,0.96-0.29,1.43c-0.96,4.05-1.74,8.15-3.15,12.07c-1.59,4.15-3.81,8.03-5.71,12.04
	c-5.08,9.81-8.37,14.92-13.43,21.56C207.07,73.51,207.01,61.47,209.29,49.67z M185.89,278.25c-3.88-0.69-4.8-1-11.71-4.04
	c-8.84-5.53-8.71-6.22-16.27-12.77c-6.21-5.53-8.4-7.49-10.35-12.16c-3.6-8.67-4.62-10.85-5.28-17.79c-0.7-6.05-1-12.12-1.08-18.21
	c-0.21-3.98,0.05-7.97,0.82-11.88c0.99-5.71,5.79-23.36,12.07-33.19c0.9-1.85,7.22-9.94,12.37-15.33
	c6.87-7.06,14.71-13.11,21.82-19.93c1.23-1.14,2.43-2.31,3.62-3.49c0.97,5.46,2.02,10.91,3.07,16.35c0.33,2.05,0.64,4.11,0.94,6.17
	c-16.2,6.66-31.21,19.31-36.39,35.97c-0.75,3.08-1.45,6.21-1.54,9.39c-0.11,3.4,0.47,6.78,0.98,10.14
	c0.89,4.63,1.68,9.41,4.01,13.58c2.02,3.4,4.5,6.56,7.46,9.2c2.16,2.2,4.6,4.25,7.79,4.62c3.32,0.59,6.81,0.07,9.73-1.65
	c2.77-1.48,4-5.42,1.56-7.76c-6.32-6.11-6.97-6.14-9.35-12.82c-1.18-4.42-1.74-6.65-2.2-14.33c0.45-6.76,0.11-5.25,2.57-11.83
	c2.76-5.66,3.58-6.61,9.54-13.05c2.12-2.12,4.88-4.27,7.76-6.14c0.26,2.58,0.49,5.17,0.67,7.75c0.79,11.49,1.69,22.97,2.78,34.44
	c1.53,13.58,2.97,27.17,3.47,40.83c0.39,8.93-0.31,17.85-1.21,26.74c-0.83,0.1-1.66,0.21-2.49,0.31
	C196,277.69,190.94,278.38,185.89,278.25z M266.92,219.18c-0.98,4.06-2.33,8.04-3.73,11.97c-3.37,8.58-4.38,10.32-8.3,15.52
	c-3.46,4.82-7.46,9.22-11.93,13.12c-1.87,1.66-3.73,3.32-5.62,4.95c-2.75,2.15-5.76,3.96-8.87,5.54c-1.7,0.75-3.16,1.37-4.46,1.89
	c0.02-0.25,0.04-0.5,0.06-0.75c2.64-26.61-2.54-53.13-4.34-79.63c-0.8-10.78-1.51-21.57-2.79-32.3c1.63-0.37,3.74-0.79,6.83-1.4
	c8.81-1.15,4.98-0.84,12.53-1.1c0.55,0.1,1.11,0.22,1.65,0.36c0.78,0.22,1.55,0.45,2.32,0.7c0.87,0.28,1.71,0.63,2.54,1.01
	c1.83,0.99,3.56,2.15,5.2,3.43c0.08,0.06,0.14,0.11,0.2,0.16c1.35,1.16,2.63,2.4,3.79,3.75c6.2,6.58,7.63,11.28,11.72,22.19
	C267,199.3,268.64,206.06,266.92,219.18z'
      />
    </svg>
  )
}

export default MusicStaff
