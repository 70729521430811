import React from 'react'

export default ({ ...props }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0V0ZM17.5035 17.307C17.4523 17.391 17.3851 17.464 17.3056 17.5219C17.2261 17.5798 17.136 17.6214 17.0404 17.6444C16.9448 17.6674 16.8456 17.6713 16.7485 17.6559C16.6514 17.6404 16.5583 17.606 16.4745 17.5545C13.656 15.834 10.11 15.444 5.9325 16.3995C5.73896 16.4437 5.5358 16.4091 5.36772 16.3035C5.19964 16.1979 5.08041 16.0298 5.03625 15.8363C4.99209 15.6427 5.02663 15.4396 5.13226 15.2715C5.23789 15.1034 5.40596 14.9842 5.5995 14.94C10.1715 13.896 14.0925 14.3445 17.2545 16.278C17.4237 16.3817 17.5449 16.5482 17.5916 16.7411C17.6382 16.9339 17.6066 17.1374 17.5035 17.307ZM18.972 14.04C18.842 14.2514 18.6335 14.4025 18.3922 14.4602C18.1508 14.5178 17.8965 14.4773 17.685 14.3475C14.46 12.366 9.543 11.7915 5.727 12.9495C5.49473 13.0047 5.25016 12.9692 5.04316 12.8503C4.83617 12.7313 4.68235 12.5379 4.61308 12.3094C4.54382 12.0809 4.56432 11.8347 4.67041 11.6208C4.7765 11.4069 4.96019 11.2416 5.184 11.1585C9.5415 9.837 14.9595 10.4775 18.663 12.753C18.8746 12.8827 19.0261 13.0912 19.084 13.3325C19.142 13.5739 19.1017 13.8284 18.972 14.04ZM19.098 10.638C15.231 8.34 8.85 8.1285 5.157 9.249C4.87216 9.33533 4.56469 9.30497 4.30223 9.1646C4.03977 9.02423 3.84383 8.78534 3.7575 8.5005C3.67117 8.21566 3.70153 7.90819 3.8419 7.64573C3.98228 7.38327 4.22116 7.18733 4.506 7.101C8.7435 5.8155 15.7905 6.063 20.244 8.706C20.5002 8.85797 20.6855 9.10549 20.7592 9.39411C20.8329 9.68273 20.789 9.9888 20.637 10.245C20.485 10.5012 20.2375 10.6865 19.9489 10.7602C19.6603 10.8339 19.3542 10.79 19.098 10.638Z'
      fill='white'
    />
  </svg>
)
