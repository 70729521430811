import React from 'react'

const QuestionMark = ({ ...props }) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      viewBox='0 0 300 300'
      {...props}
    >
      <path
        d='M200.45,94.91c0.71-2.83,1.49-5.66,1.58-8.6c1.57-5.31,2.47-10.89,2.47-16.43c-0.26-7.41-4.96-13.43-10.58-17.74
	c-3.28-2.83-6.43-5.84-10.24-7.93c-8.79-4.8-18.38-8.23-28.18-10.13c-11.37-1.48-22.95-0.09-33.91,3.2
	c-7.98,2.86-17.14,6.52-21.41,14.43c-3.08,6.37-5.44,13.4-4.45,20.58c0.43,2.75,0.45,6.25,3.01,7.9c4.96,5.33,22.63,3.15,19.91-6.32
	c-0.13-0.92-0.22-1.84-0.31-2.76c-0.11-2.13-0.14-4.28,0.19-6.4c0-0.01,0-0.01,0-0.02c1.06-3.07,2.11-6.19,3.59-9.08
	c0.27-0.31,0.57-0.61,0.88-0.89c1.09-0.76,2.6-1.62,3.37-2.06c1.97-0.93,3.98-1.75,6.06-2.41c8.69-2.12,10.01-1.91,14.71-2.04
	c0.79,0.04,1.57,0.09,2.36,0.13c0.32,0.04,0.63,0.08,0.95,0.13c1.73,0.45,0.2-0.27,7.09,2.02c9.69,3.45,11.76,4.93,14.75,7.34
	c5.8,5.33,3.91,2.56,8.5,8.33c0.3,0.64,0.53,1.31,0.74,1.99c0.03,0.1,0.05,0.19,0.07,0.29c0.27,1.82,0.24,3.67,0.01,5.49
	c-0.28,1.78-0.37,3.58-0.66,5.36c-0.36,1.45-0.79,2.89-1.13,4.34c-0.23,0.63-0.38,1.28-0.42,1.96c-0.18,1.05-0.2,2.12-0.32,3.17
	c-0.28,1.09-0.57,2.17-0.82,3.27c-1.28,5.65-0.3,4.42-1.59,6.93c-0.22,0.34-0.44,0.68-0.66,1.02c-0.41,0.75-0.79,1.53-1.18,2.3
	c-0.5,0.31-1.01,0.6-1.5,0.92c-2.85,1.89-5.72,3.75-8.72,5.37c-5.44,2.56-11,4.87-16.22,7.89c-3.64,1.75-7.4,3.35-10.5,5.97
	c-3.86,3.09-6.64,7.82-6.67,12.88c0.04,1.21,0.24,2.39,0.49,3.57c0.53,3.44,0.68,6.93,1.52,10.33c0.62,3.22,2.38,6.07,3.24,9.22
	c0.81,4.55,1.32,9.15,2.3,13.66c0.46,2.64,1.31,5.18,2.01,7.76c0.63,4.66,0.71,9.43,2.19,13.94c0.49,1.5,1.32,2.88,1.69,4.41
	c0.16,0.83,0.33,1.66,0.49,2.49c0.25,1.48,0.06,3.14,1.16,4.29c3.94,6.01,15.45,5.82,20.2,0.88c2.49-2.41,1.21-5.81,0.66-8.75
	c-0.29-3.04-1.85-5.64-2.63-8.51c-0.07-0.4-0.15-0.81-0.21-1.21c-0.42-2.81-0.41-5.68-0.94-8.48c-0.52-3.02-1.62-5.88-2.3-8.86
	c0-0.01,0-0.02-0.01-0.03c-0.88-4.69-1.36-9.44-2.38-14.1c-0.57-3.3-2.3-6.21-3.26-9.38c-0.45-2.63-0.59-5.31-1.04-7.94
	c-0.09-0.49-0.2-1.01-0.31-1.52c-0.05-0.4-0.08-0.81-0.11-1.21c-0.06-0.58-0.04-1.17,0.03-1.75c0.08-0.26,0.17-0.51,0.28-0.76
	c0.07-0.16,0.16-0.3,0.25-0.45c0.39-0.54,0.83-1.04,1.3-1.52c5.65-3.36,3.33-1.51,11.39-6.03c1.62-0.82,1.83-0.71,8.05-3.68
	c4.52-2.09,8.93-4.47,13.01-7.35c2.77-1.76,5.9-3.31,7.48-6.35c0.71-1.16,1.27-2.41,1.9-3.61c0.85-1.26,1.61-2.58,1.84-4.12
	C199.95,98.5,200.19,96.7,200.45,94.91z'
      />
      <path d='M162.86,251.74c-15.04,0.35-15.01,14.38,0,14.76C177.91,266.15,177.87,252.12,162.86,251.74z' />
    </svg>
  )
}

export default QuestionMark
