import React from 'react'

const Play = ({ ...props }) => {
  return (
    <svg
      width='154'
      height='154'
      viewBox='0 0 154 154'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M77 0C34.4781 0 0 34.4781 0 77C0 119.522 34.4781 154 77 154C119.522 154 154 119.522 154 77C154 34.4781 119.522 0 77 0ZM101.767 78.1859L64.2297 105.497C64.0239 105.645 63.7813 105.733 63.5286 105.752C63.2759 105.771 63.0229 105.72 62.7973 105.605C62.5717 105.489 62.3823 105.314 62.2499 105.098C62.1175 104.882 62.0473 104.633 62.0469 104.38V49.7922C62.046 49.5383 62.1155 49.2891 62.2477 49.0722C62.3798 48.8554 62.5694 48.6794 62.7954 48.5637C63.0214 48.448 63.2751 48.3972 63.5283 48.4168C63.7814 48.4365 64.0242 48.5258 64.2297 48.675L101.767 75.9688C101.945 76.0942 102.089 76.2603 102.189 76.4532C102.289 76.6461 102.341 76.8601 102.341 77.0773C102.341 77.2946 102.289 77.5086 102.189 77.7015C102.089 77.8944 101.945 78.0605 101.767 78.1859Z'
        fill='#F53C5A'
      />
    </svg>
  )
}

export default Play
