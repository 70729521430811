import React from 'react'

export default ({ ...props }) => (
  <svg
    width='18'
    height='36'
    viewBox='0 0 18 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2.55433 35.9954C1.95856 35.9965 1.38118 35.7877 0.922432 35.4052C0.664238 35.1898 0.450812 34.9253 0.294374 34.6268C0.137935 34.3283 0.0415607 34.0016 0.0107691 33.6656C-0.0200225 33.3295 0.0153745 32.9907 0.114932 32.6684C0.214489 32.3461 0.376249 32.0468 0.590952 31.7875L12.0143 18.0352L0.998929 4.25717C0.787124 3.99473 0.628952 3.69275 0.533507 3.36859C0.438062 3.04444 0.407224 2.7045 0.442767 2.36832C0.47831 2.03213 0.579531 1.70633 0.740614 1.40964C0.901698 1.11294 1.11947 0.85121 1.38141 0.639478C1.64523 0.405902 1.95418 0.229719 2.28888 0.121986C2.62358 0.0142533 2.9768 -0.0227028 3.32636 0.0134354C3.67593 0.0495735 4.0143 0.158027 4.32024 0.331988C4.62617 0.50595 4.89307 0.741664 5.10418 1.02434L17.4199 16.4188C17.795 16.8779 18 17.4537 18 18.048C18 18.6423 17.795 19.2182 17.4199 19.6773L4.67071 35.0717C4.41491 35.3822 4.08998 35.6276 3.72227 35.7881C3.35455 35.9486 2.95442 36.0196 2.55433 35.9954Z'
      fill='white'
    />
  </svg>
)
