import React from 'react'

const LogoMain = ({ ...props }) => (
  <svg
    version='1.1'
    id='tss-logo'
    x='0px'
    y='0px'
    width='150px'
    height='150px'
    viewBox='0 0 600 600'
    className='text-green-300 fill-current'
    {...props}
  >
    <path d='M261.46,87.47c-0.05-0.02-0.09-0.05-0.13-0.08' />
    <path
      d='M226.33,255.03c-0.14-1.52-0.79-2.83-1.96-3.93c-3.79-3.81-9.02-5.1-14.25-5.23c-6.45-0.3-11.92,3.41-17.9,5.19
c-0.14,0.04-0.27,0.08-0.41,0.12c-5.67,1.3-11.5,1.74-17.24,2.59c-9.09,1.08-18.2,1.91-27.28,3.08c-8.86,1.21-17.65,2.85-26.47,4.33
c-4.32,0.73-8.62,1.56-12.98,2.02c-3.51,0.34-7.21,0.44-10.28,2.35c-2.84,1.21-5.89,3.64-6.21,6.89c-1,2.45,0.54,4.78,1.96,6.73
c4.73,4.8,12.2,5.89,18.63,4.85c11.59-1,22.98-3.54,34.46-5.35c9.74-1.65,19.57-2.63,29.38-3.68c12.71-1.46,25.68-2.51,37.66-7.32
c3.24-1.51,7-2.35,9.64-4.84C225.49,261.22,227.38,257.91,226.33,255.03z'
    />
    <path
      d='M505.43,280.51c-2.27-2.33-5.53-2.98-8.57-3.78c-6.64-1.17-13.68,0.3-18.71,4.79c-6.79,1.86-13.27,4.71-19.54,7.9
c-4.44,2.06-8.41,4.91-12.48,7.6c-6.21,3.91-12.23,8.16-18.52,11.96c-2.17-2.97-4.7-5.63-7.89-7.59c-3.03-1.97-6.38-3.41-9.42-5.33
c0.07-2.53,0.26-5.05,0.71-7.55c0.13-0.53,0.25-1.07,0.4-1.6c0.28-0.99,0.63-1.97,1.05-2.91c3.21-2.24,6.44-4.46,9.66-6.7
c7.77-6.26,15.8-12.2,23.55-18.49c1.76-1.41,3.47-2.89,5.19-4.34c1.94-1.48,4.17-2.7,5.34-4.93c5.24-7.3-4.74-14.23-11.48-14.75
c-9.04-1.08-18.24,1.17-26.33,5.23c-10.99,5.01-20.53,13.11-27.49,22.97c-2.17,3.27-4.64,6.38-6.84,9.65
c-0.52,0.36-1.03,0.72-1.55,1.08c-17.64,13.41-35.6,26.4-53.7,39.18c-0.57-0.98-1.2-1.93-1.87-2.86c-2.31-3.33-4.99-6.41-8.27-8.82
c-5.03-3.85-11.14-5.81-17.23-7.34c1.29-3.92,2.35-4.33,3.32-6.07c2.27-3.24,4.44-6.55,6.97-9.6c2.84-3.21,5.9-6.22,8.82-9.36
c0.7-0.74,1.41-1.48,2.11-2.22c2.71-1.63,5.41-3.27,8.08-4.95c2-1.26,4.09-2.36,6.19-3.45c1.55-0.75,3.1-1.49,4.63-2.25
c6.73-2.77,11.52-10.36,4.37-15.74c-3.42-3.16-8.42-4.07-12.96-4.16c-8.53-0.1-16.37,4.02-23.69,8c-4.37,2.38-8.26,5.4-11.89,8.74
c-11.19,6.74-21.89,14.21-32.07,22.4c-20,14.81-36.76,31.11-49.97,40.94c-5.9,4.65-11.97,9.06-18.3,13.12
c-4.94,3.2-10.15,5.99-15.1,9.19c-15.07,9.24-15.38,11.13-28.34,15.43c-0.19,0.04-0.37,0.08-0.56,0.12
c-0.23-0.63-0.45-1.27-0.63-1.92c-0.06-0.39-0.11-0.78-0.15-1.18c-0.52-3.87-0.03-7.77,0.8-11.56c1.89-9.02,0.55-2.8,2.81-11.88
c1.73-6.6,3.63-13.15,5.79-19.62c2.56-6.68,5.18-13.33,7.6-20.06c4.3-11.48,7.85-23.26,13.08-34.36c2.53-5.68,5.26-11.27,8-16.86
c1.15-3.13,4.2-6.29,2.98-9.76c-0.31-3.25-3.38-5.68-6.21-6.89c-7.61-4.11-21.73-2.84-25.91,5.49c-4.35,8.94-8.73,17.87-12.76,26.96
c-3.84,9.19-6.92,18.66-10.34,28c-2.69,7.6-5.73,15.07-8.51,22.63c-2.75,8.2-4.98,16.57-7.11,24.95
c-1.22,5.33-2.39,10.72-3.24,16.12c-0.53,4.09,0.07,8.21,1.01,12.2c2.68,12.77,13.07,16.63,24.64,18.83c9.5,1.41,19.02-0.81,28-3.84
c11.88-4.16,22.19-11.39,32.73-18.09c6.18-3.92,12.65-7.37,18.65-11.57c8.34-5.49,16.1-11.81,23.92-18.01
c9.33-7.89,16.5-14.07,23.32-19.79c1.4,1.96,3.17,3.65,5.24,4.92c4.49,2.58,9.46,3.89,14.43,5.16c0.63,0.19,1.25,0.41,1.85,0.66
c0.26,0.15,0.52,0.31,0.78,0.48c0.22,0.22,0.43,0.44,0.64,0.67c1.11,1.24,2.14,2.55,3.13,3.9c0.11,0.22,0.22,0.43,0.32,0.66
c0.32,0.74,0.55,1.51,0.74,2.3c0.16,0.85,0.3,1.71,0.39,2.57c0.38,2.98,0.06,5.97-0.38,8.93c-6.03,4-12.3,7.63-18.44,11.47
c-14.81,9.07-15.7,9.29-27.49,14.5c-3.56,1.14-7.09,3.22-8.71,6.72c-0.2,0.94-0.39,1.87-0.59,2.81c0.5,7.75,10.07,10.41,16.57,10.62
c4.43-0.13,8.75-0.99,12.96-2.35c8.04-1.99,15.84-4.71,23.3-8.3c11.38-5.66,24.5-10.6,31.19-22.14c0.79-1.13,1.4-2.33,1.91-3.58
c4.42-3.15,8.88-6.25,13.33-9.36c13.06-9.1,26.03-18.32,38.87-27.72c1.33,1.38,2.87,2.6,4.47,3.69c3.38,2.31,7.24,3.83,10.62,6.12
c0.69,0.74,1.31,1.54,1.9,2.36c0.44,0.72,0.86,1.45,1.21,2.21c-3.66,1.72-7.25,3.6-10.69,5.76c-5.2,3.24-9.77,7.35-14.28,11.47
c-7.99,7.34-16.56,14.15-23.93,22.12c-1.56,1.7-2.91,3.63-3.95,5.69c-1.31,4-1.15,9.25,2.76,11.71c6.5,5.36,15.93,4.63,23.88,4.29
c9.34-0.52,18.55-2.22,27.42-5.22c4.79-1.74,9.03-4.56,12.85-7.91c8.66-6.37,15.14-15.61,17.76-26.08
c0.84-3.42,1.55-7.02,1.91-10.63c2.13-0.83,4.46-1.74,7.06-2.78c14.29-6.61,26.4-17.02,40.18-24.5c1.54-0.71,3.09-1.4,4.66-2.03
c2.01-0.8,4.04-1.55,6.09-2.23c3.04-0.75,6.01-1.72,8.73-3.32c1.83-1.06,3.36-2.57,5.01-3.88c1.66-1.34,3.33-3,3.43-5.23
C509.73,285.44,507.82,282.12,505.43,280.51z'
    />
  </svg>
)

export default LogoMain
